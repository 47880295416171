.juse-form {
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  padding: 2rem;
}

.output-log {
  margin-top: 2rem;
}

button.info {
  float: right;
  border-radius: 50%;
  min-width: 38px;
  max-width: 2.5rem;
  overflow: none;
  text-align: center;
  padding: 0;
}
button.info::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
}

button.info svg {
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
}

.ctm-root {
  margin: auto;
  max-width: 768px;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerimg {
  filter: drop-shadow(1.15px 1.15px 0 #ffffff30)
    drop-shadow(-1.15px -1.15px 0 #ffffff30);
}

.anchor-disguised:target,
.anchor-disguised:focus-visible,
.anchor-disguised:target,
.anchor-disguised:target {
  border: 1px solid #ffffff99 !important;
}

.anchor-disguised {
  padding: 0 !important;
  text-align: center;
  aspect-ratio: 1;
}

button svg {
  vertical-align: top;
}
